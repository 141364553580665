import React, { useState, useEffect } from "react";
import {
  TableCell,
  TableRow,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  IconButton,
  InputAdornment,
  Link,
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import SaveIcon from "@mui/icons-material/Save";
import styles from "../styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import { connect } from "react-redux";
import { getPersonInfoWithName } from "../../../redux/demographics/demographicsActions";
import { getStudentApiAccommodations } from "../../../services/student/v1/student";
import { updateStudentAccommodation } from "../../../services/faculty/v1/faculty";

function CourseTableRow(props) {
  const useStyles = styles;
  const classes = useStyles();

  const [originalNote, setOriginalNote] = useState(
    props.student.notes === "null" ? "" : props.student.notes
  );
  const [noteValue, setNoteValue] = useState(
    props.student.notes === "null" ? "" : props.student.notes
  );
  const [noteHasChanged, setNoteHasChanged] = useState(false);
  const [disabilityTypes, setDisabilityTypes] = useState("");
  const [accommodationTypesAcknowledged, setAccommodationTypesAcknowledged] =
    useState([]);
  const [newAccommodationTypes, setNewAccommodationTypes] = useState([]);

  const [focused, setFocused] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");

  const [gradeValue, setGradeValue] = useState(
    props.student?.isFinalGradePosted
  );
  const [accommodationObject, setAccommodationObject] = useState({});
  const [hasAcknowledgedAccommodation, setHasAcknowledgedAccommodation] =
    useState(false);

  const [mainRowInfoObject, setMainRowInfoObject] = useState({
    studentAccommodationInfoId: props.student?.studentAccommodationInfoId,
    facultyIrn: props.student?.facultyIrn,
    facultyPersonId: props.student?.facultyPersonId,
    studentIrn: props.student?.studentIrn,
    studentPersonId: props.student?.studentPersonId,
    studentName: props.student?.fullName,
    notes: props.student?.notes,
    finalGradePosted: props.student?.isFinalGradePosted,
    courseId: props.courseId,
    courseOfferingId: props.courseOfferingId,
    courseStartDate: props.courseStartDate,
    courseEndDate: props.courseEndDate,
    receivingFromFwsDatabase: props.student?.receivingFromFwsDatabase,
    dateCreated: props.student?.dateCreated,
    createdBy: props.student?.createdBy,
    dateModified: props.student?.dateModified,
    modifiedBy: props.student?.modifiedBy,
    displayAccommodationTypes: [], //This is for display accommodation types in ActionNeeded.jsx
    dbResponse: null,
  });
  
  useEffect(() => {
    setAccommodationObject({
      id: props.student.studentAccommodationInfoId,
      facultyIRN: props.facultyDemographics.issuerId,
      facultyPersonId: props.facultyDemographics.personId,
      studentIRN: props.student.studentIRN,
      studentPersonId: props.student.studentPersonId,
      courseOfferingId: props.student.courseOfferingId,
      notes: props.student.notes,
      isFinalGradePosted: props.student.isFinalGradePosted,
      dateCreated: props.student.dateCreated,
      dateModified: props.student.dateModified,
      modifiedBy: props.student.modifiedBy,
    });
  }, [props.student]);

  // =========================================================================================================================
  // Call for SRM endpoint if student information does not come from fws database.
  const [studentSrmApiInfo, setStudentSrmApiInfo] = useState({});

  const handleSrmApiEndpoint = async (personId) => {
    await getStudentApiAccommodations(personId)
      .then((response) => {
        setStudentSrmApiInfo(response);
      })
      .catch((error) => {
        setSnackbarType("error");
        setSnackbarMessage(
          "Failed to call get student accommdations from srm."
        );
        setOpenSuccess(true);
        console.log(error.request);
      });
  };

  useEffect(() => {
    if (
      !props.student.receivingFromFwsDatabase &&
      props.student.studentPersonId
    ) {
      handleSrmApiEndpoint(props.student.studentPersonId);
    }
  }, [props.student]);
  // =========================================================================================================================

  // =========================================================================================================================
  // Set new and already acknowledged acknowledgements to display to the the screen for student.
  const [hasNewAccommodation, setHasNewAccommodation] = useState(false); //sets row red/white

  useEffect(() => {
    if (props.student.receivingFromFwsDatabase) {
      handleFwsData();
    } else {
      handleSrmData();
    }
  }, [studentSrmApiInfo, props.update, props.student]);

  const handleUpdatedRow = (facultyAcknowledgements) => {
    let tempAccommodationTypesAcknowledged = [];

    if (facultyAcknowledgements?.length >= 1) {
      facultyAcknowledgements.forEach((accommodation) => {
        const isNullDates =
          accommodation.accommodationStartDate === null &&
          accommodation.accommodationEndDate === null;

        if (accommodation.dateAcknowledged) {
          setHasAcknowledgedAccommodation(true);
        }

        const isWithinDateRange = () => {
          if (!isNullDates) {
            const startDate = new Date(accommodation.accommodationStartDate);
            const endDate = new Date(accommodation.accommodationEndDate);
            const courseStartDate = mainRowInfoObject.courseStartDate
              ? new Date(mainRowInfoObject.courseStartDate)
              : null;
            const courseEndDate = mainRowInfoObject.courseEndDate
              ? new Date(mainRowInfoObject.courseEndDate)
              : null;
            return (
              (endDate &&
                endDate >= courseStartDate &&
                endDate <= courseEndDate) ||
              (startDate &&
                startDate >= courseStartDate &&
                startDate <= courseEndDate) ||
              (startDate &&
                endDate &&
                startDate <= courseStartDate &&
                endDate >= courseEndDate)
            );
          }
          return true;
        };

        const accommodationCourseMatch =
          accommodation.courseId === null
            ? true
            : accommodation.courseId === props.courseId
            ? true
            : false;

        const isApproved =
          accommodation.status?.toLowerCase() === "approved" ||
          accommodation.status === null;

        if (accommodationCourseMatch && isWithinDateRange() && isApproved) {
          tempAccommodationTypesAcknowledged.push(
            accommodation.accommodationType
          );
        }
      });
    }
    return tempAccommodationTypesAcknowledged;
  };

  // Helper function to process accommodations
  const processAccommodations = (facultyAcknowledgements) => {
    let tempAccommodationTypesAcknowledged = [];
    let tempNewAccommodationTypes = [];

    if (facultyAcknowledgements?.length >= 1) {
      facultyAcknowledgements.forEach((accommodation) => {
        const isNullDates =
          accommodation.accommodationStartDate === null &&
          accommodation.accommodationEndDate === null;

        if (accommodation.dateAcknowledged) {
          setHasAcknowledgedAccommodation(true);
        }

        const isWithinDateRange = () => {
          if (!isNullDates) {
            const startDate = new Date(accommodation.accommodationStartDate);
            const endDate = new Date(accommodation.accommodationEndDate);
            const courseStartDate = mainRowInfoObject.courseStartDate
              ? new Date(mainRowInfoObject.courseStartDate)
              : null;
            const courseEndDate = mainRowInfoObject.courseEndDate
              ? new Date(mainRowInfoObject.courseEndDate)
              : null;
            return (
              (endDate &&
                endDate >= courseStartDate &&
                endDate <= courseEndDate) ||
              (startDate &&
                startDate >= courseStartDate &&
                startDate <= courseEndDate) ||
              (startDate &&
                endDate &&
                startDate <= courseStartDate &&
                endDate >= courseEndDate)
            );
          }
          return true;
        };

        const accommodationCourseMatch =
          accommodation.courseId === null
            ? true
            : accommodation.courseId === props.courseId
            ? true
            : false;

        const isApproved =
          accommodation.status?.toLowerCase() === "approved" ||
          accommodation.status === null;

        if (accommodationCourseMatch && isWithinDateRange() && isApproved) {
          if (accommodation.dateAcknowledged === null) {
            tempNewAccommodationTypes.push(accommodation.accommodationType);
            setHasNewAccommodation(true);
          } else {
            tempAccommodationTypesAcknowledged.push(
              accommodation.accommodationType
            );
          }
        }
      });
    }

    return { tempAccommodationTypesAcknowledged, tempNewAccommodationTypes };
  };

  // Handle FWS Database data
  const handleFwsData = () => {
    if (props.student.facultyAcknowledgements) {
      if (
        props.update.studentIrn === String(props.student.studentIrn) &&
        props.update.courseOfferingId === String(props.courseOfferingId)
      ) {
        const tempAccommodationTypesAcknowledged = handleUpdatedRow(
          props.student.facultyAcknowledgements
        );

        setDisabilityTypes("ADA");
        setMainRowInfoObject({
          ...mainRowInfoObject,
          studentAccommodationInfoId: props.student?.studentAccommodationInfoId,
          studentName: props.student?.fullName,
          studentPersonId: props.student?.studentPersonId,
          studentIrn: props.student?.studentIrn,
          receivingFromFwsDatabase: props.student?.receivingFromFwsDatabase,
          displayAccommodationTypes: [...tempAccommodationTypesAcknowledged],
          dbResponse: props.student?.facultyAcknowledgements,
        });
        setAccommodationTypesAcknowledged(tempAccommodationTypesAcknowledged);
        setNewAccommodationTypes([]);
        setHasNewAccommodation(false);
      } else {
        const {
          tempAccommodationTypesAcknowledged,
          tempNewAccommodationTypes,
        } = processAccommodations(props.student.facultyAcknowledgements);

        setDisabilityTypes("ADA");
        setMainRowInfoObject({
          ...mainRowInfoObject,
          studentAccommodationInfoId: props.student?.studentAccommodationInfoId,
          studentName: props.student?.fullName,
          studentPersonId: props.student?.studentPersonId,
          studentIrn: props.student?.studentIrn,
          receivingFromFwsDatabase: props.student?.receivingFromFwsDatabase,
          displayAccommodationTypes: [
            ...tempAccommodationTypesAcknowledged,
            ...tempNewAccommodationTypes,
          ],
          dbResponse: props.student?.facultyAcknowledgements,
        });
        setNewAccommodationTypes(tempNewAccommodationTypes);
        setAccommodationTypesAcknowledged(tempAccommodationTypesAcknowledged);

        if (props.student.courseOfferingId === 0) {
          setHasNewAccommodation(true);
        }
      }
    }
  };

  // Handle SRM data
  const handleSrmData = () => {
    if (studentSrmApiInfo.status === 200) {
      let tempNewAccommodationTypes = [];
      let srmAccommodations =
        studentSrmApiInfo.data.accommodationType !== null &&
        studentSrmApiInfo.data.isActive
          ? studentSrmApiInfo.data.accommodationType.split(";")
          : [];
      srmAccommodations =
        studentSrmApiInfo.data.specialArrangements !== null &&
        studentSrmApiInfo.data.specialArrangements !== ""
          ? [...srmAccommodations, studentSrmApiInfo.data.specialArrangements]
          : [...srmAccommodations];
      const srmTempAccommodations = studentSrmApiInfo.data.tempAccoms || [];

      tempNewAccommodationTypes = [...srmAccommodations];

      srmTempAccommodations.forEach((accommodation) => {
        if (accommodation.accommodationType) {
          const courseStartDate = mainRowInfoObject.courseStartDate
            ? new Date(mainRowInfoObject.courseStartDate)
            : null;
          const courseEndDate = mainRowInfoObject.courseEndDate
            ? new Date(mainRowInfoObject.courseEndDate)
            : null;
          const startDate = accommodation.startDate
            ? new Date(accommodation.startDate)
            : null;
          const endDate = accommodation.endDate
            ? new Date(accommodation.endDate)
            : null;
          const isApproved =
            accommodation.status?.toLowerCase() === "approved" ||
            accommodation.status === null;
          // if courseId is null - it is not specific to a course. otherwise, courseId has to match
          const accommodationCourseMatch =
            accommodation.courseId === null
              ? true
              : accommodation.courseId === props.courseId
              ? true
              : false;

          if (isApproved && accommodationCourseMatch) {
            const conditions = [
              // End date exists and is within range of course start date and course end date
              endDate && endDate >= courseStartDate && endDate <= courseEndDate,
              // Start date exists and is within range of course start date and course end date
              startDate &&
                startDate >= courseStartDate &&
                startDate <= courseEndDate,
              // Both dates exist and start date is before course start date and end date is after course end date
              startDate &&
                endDate &&
                startDate <= courseStartDate &&
                endDate >= courseEndDate,
              // Both dates are null
              !startDate && !endDate,
              // Only startDate exists and current date is after startDate
              startDate && !endDate && courseStartDate >= startDate,
              // Only endDate exists and current date is before endDate
              !startDate && endDate && courseEndDate <= endDate,
            ];

            if (conditions.some((condition) => condition)) {
              let tempSrmAccommodations =
                accommodation.accommodationType !== null
                  ? accommodation.accommodationType.split(";")
                  : [];
              tempNewAccommodationTypes = [
                ...tempNewAccommodationTypes,
                ...tempSrmAccommodations,
              ];
              if (
                accommodation.specialArrangements !== null &&
                accommodation.specialArrangements !== ""
              ) {
                tempNewAccommodationTypes = [
                  ...tempNewAccommodationTypes,
                  accommodation.specialArrangements,
                ];
              }
            }
          }
        }
      });

      setHasNewAccommodation(true);
      setMainRowInfoObject({
        ...mainRowInfoObject,
        studentAccommodationInfoId: props.student?.studentAccommodationInfoId,
        studentName: props.student?.fullName,
        studentPersonId: props.student?.studentPersonId,
        studentIrn: props.student?.studentIrn,
        receivingFromFwsDatabase: props.student?.receivingFromFwsDatabase,
        displayAccommodationTypes: [...tempNewAccommodationTypes],
        dbResponse: studentSrmApiInfo.data,
      });
      setNewAccommodationTypes(tempNewAccommodationTypes);
      setAccommodationTypesAcknowledged([]);
      setDisabilityTypes("ADA");
    }
  };

  const handleNotesChange = (event) => {
    if (originalNote !== event.target.value) {
      setNoteHasChanged(true);
    } else {
      setNoteHasChanged(false);
    }

    setNoteValue(event.target.value);
  };

  const handleSaveNotes = () => {
    // Use a callback to ensure the state is fully updated before calling the API
    setAccommodationObject((prevState) => {
      const updatedObject = {
        ...prevState,
        dateCreated: props.student.dateCreated,
        dateModified: new Date().toISOString(),
        // modifiedBy: `${props.facultyDemographics.firstName} ${props.facultyDemographics.lastName}`,
        modifiedBy: `${props.facultyDemographics?.username}`,
        notes: noteValue,
      };

      handleUpdateStudentAccommodation(updatedObject, "notes");
    });
  };

  const handleOpenModal = () => {
    props.setActionNeededObject(mainRowInfoObject);
    if (hasNewAccommodation) {
      props.setAlreadyAcknowledged(false);
    } else {
      props.setAlreadyAcknowledged(true);
    }
    props.setOpenModal(true);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleGradeChange = (event) => {
    const value = event.target.value === "Yes" ? true : false;

    // Use a callback to ensure the state is fully updated before calling the API
    setAccommodationObject((prevState) => {
      const updatedObject = {
        ...prevState,
        dateCreated: props.student.dateCreated,
        dateModified: new Date().toISOString(),
        // modifiedBy: `${props.facultyDemographics.firstName} ${props.facultyDemographics.lastName}`
        modifiedBy: `${props.facultyDemographics?.username}`,
        isFinalGradePosted: value,
      };

      handleUpdateStudentAccommodation(updatedObject, "grade");
      return updatedObject;
    });
  };

  const handleUpdateStudentAccommodation = async (accommodation, type) => {
    await updateStudentAccommodation(accommodation)
      .then((res) => {
        switch (type) {
          case "grade":
            setSnackbarMessage("Updated final grade");
            setGradeValue(accommodation.isFinalGradePosted);
            setMainRowInfoObject((prevState) => ({
              ...prevState,
              finalGradePosted: accommodation.isFinalGradePosted,
            }));
            break;
          case "notes":
            setSnackbarMessage("Updated notes");
            setNoteHasChanged(false);
            setNoteValue(accommodation.notes);
            setMainRowInfoObject((prevState) => ({
              ...prevState,
              notes: accommodation.notes,
            }));
            break;
          default:
            break;
        }
        setSnackbarType("success");
        setOpenSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setSnackbarType("error");
        setSnackbarMessage("Failed to update student accommodation");
        setOpenSuccess(true);
      });
  };

  // =========================================================================================================================
  // Below code is to handle snackbars for successful or errored submissions
  const renderSnackbar = () => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={snackbarType === "success" ? 10000 : 13000}
        onClose={handleCloseSnackBar}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={snackbarMessage}
        />
      </Snackbar>
    </>
  );

  const handleCloseSnackBar = (event, reason) => {
    setOpenSuccess(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const handleRetryApiCall = () => {
    switch (snackbarMessage) {
      case "Failed to update student accommodation.":
        handleUpdateStudentAccommodation(accommodationObject);
        break;
      case "Failed to call get student accommdations from srm.":
        handleSrmApiEndpoint(props.student.studentPersonId);
        break;
      default:
        break;
    }
  };
  // =========================================================================================================================

  const accommodations = [
    { text: "Ability to record lecture", id: "Ability-To-Record-Lecture" },
    {
      text: "Alternatively Formatted Textbooks and/or class materials",
      id: "Alternatively-Formatted-Textbooks-And-Or-Class-Materials",
    },
    { text: "ASL Interpreter", id: "American-Sign-Language Interpreter" },
    {
      text: "Communication Access Real-Time Transcription (CART)",
      id: "Communication-Access-Real-Time-Transcription-(Live-Meeting-Captions)",
    },
    {
      text: "Email summary of in person or phone meetings",
      id: "Email-Summary-Of-In-Person-Or-Phone-Meetings",
    },
    {
      text: "Extended Time Accommodation for Individual Assignments",
      id: "Extended-Time-For-Individual-Assignments",
    },
    {
      text: "Extended Time for Competency Assessment",
      id: "Extended-Time-For-Competency-Assessments",
    },
    {
      text: "Extended Time for Exams & Quizzes",
      id: "Extended-Time-For-Exams-And-Quizzes",
    },
    { text: "Frequent breaks", id: "Frequent-Breaks" },
    {
      text: "Individual presentations or recorded presentations",
      id: "Individual-Or-Recorded-Presentations",
    },
    { text: "Laptop and tablet use in classroom", id: "Laptop-Used-In-Class" },
    { text: "Note-taking", id: "Note-Taking" },
    {
      text: "Phone appt to clarify course info or assignment expectations",
      id: "Phone-Appointment-To-Clarify-Expectations",
    },
    { text: "Preferential seating", id: "Preferential-Seating" },
    { text: "Printed text", id: "Printed-Text" },
    { text: "Quiet testing room", id: "Quiet-Testing-Room" },
    { text: "Writing Lab", id: "Writing-Lab" },
  ];

  const findAccommodationMatch = (accommodation) => {
    // Find the matching accommodation object
    const match = accommodations.find(
      (item) => item.text?.toLowerCase() === accommodation.toLowerCase()
    );

    // Return the id if a match is found, otherwise return null
    return match ? match.id : null;
  };

  return (
    <>
      <TableRow
        key={props.student.studentIrn}
        className={
          hasNewAccommodation ||
          mainRowInfoObject.displayAccommodationTypes.length === 0
            ? classes.newAccomContainer
            : classes.rowContainer
        }
      >
        <TableCell
          align="left"
          className={
            hasNewAccommodation ||
            mainRowInfoObject.displayAccommodationTypes.length === 0
              ? classes.newAccomStickyColumn
              : classes.stickyColumn
          }
        >
          <Typography
            id={`studentFullName${props.key}-${props.courseOfferingId}`}
            className={classes.boldText}
          >
            {props.student.fullName !== "null null"
              ? props.student.fullName
              : ""}
          </Typography>{" "}
          {props.student.studentIrn}
        </TableCell>
        <TableCell align="left">{disabilityTypes}</TableCell>
        <TableCell align="left">
          {newAccommodationTypes.length > 0 &&
            accommodationTypesAcknowledged.length > 0 && (
              <div className={classes.newAccommodationIcon}>
                <Typography className={classes.newAccommodationIconText}>
                  NEW
                </Typography>
              </div>
            )}
          {newAccommodationTypes.length > 0 &&
            newAccommodationTypes.map((accommodation, index) => {
              let linkId;
              let hrefValue;
              if (accommodation) {
                linkId = findAccommodationMatch(accommodation);
                hrefValue = linkId
                  ? `${process.env.REACT_APP_STUDENT_ACCOMMODATION_DICTIONARY_URL}#${linkId}`
                  : "#"; // Default to "#" if no match is found
              }
              return (
                <React.Fragment key={index}>
                  <div>
                    {hrefValue === "#" ? (
                      <Typography display="inline">
                        {`${accommodation}`}
                        {index < newAccommodationTypes.length - 1 && (
                          <span style={{ marginLeft: "2px" }}>{`;`}</span>
                        )}
                      </Typography>
                    ) : (
                      <Link
                        component="a"
                        underline="none"
                        href={hrefValue}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.linkText}
                        style={{
                          margin: "0px 4px 2px 0",
                          lineHeight: 1.5,
                        }}
                      >
                        <Typography display="inline">
                          {`${accommodation}`}
                          {index < newAccommodationTypes.length - 1 && (
                            <span style={{ marginLeft: "2px" }}>{`;`}</span>
                          )}
                        </Typography>
                      </Link>
                    )}
                  </div>
                </React.Fragment>
              );
            })}
          {newAccommodationTypes.length > 0 &&
            accommodationTypesAcknowledged.length > 0 && (
              <>
                <br />
                <br />
              </>
            )}
          {accommodationTypesAcknowledged.map((accommodation, index) => {
            let linkId;
            let hrefValue;
            if (accommodation) {
              linkId = findAccommodationMatch(accommodation);
              hrefValue = linkId
                ? `${process.env.REACT_APP_STUDENT_ACCOMMODATION_DICTIONARY_URL}#${linkId}`
                : "#";
            }

            return (
              <React.Fragment key={index}>
                <div
                  style={{
                    marginRight: "4px",
                    marginBottom: "2px",
                  }}
                >
                  {hrefValue === "#" ? (
                    <Typography display="inline">
                      {`${accommodation}`}
                      {index < accommodationTypesAcknowledged.length - 1 && (
                        <span style={{ marginLeft: "2px" }}>{`;`}</span>
                      )}
                    </Typography>
                  ) : (
                    <Link
                      component="a"
                      underline="none"
                      href={hrefValue}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.linkText}
                      style={{
                        lineHeight: 1.5,
                      }}
                    >
                      <Typography display="inline">
                        {`${accommodation}`}
                        {index < accommodationTypesAcknowledged.length - 1 && (
                          <span style={{ marginLeft: "2px" }}>{`;`}</span>
                        )}
                      </Typography>
                    </Link>
                  )}
                </div>
              </React.Fragment>
            );
          })}
        </TableCell>
        <TableCell>
          <TextField
            id="description-notes"
            name="course"
            onChange={handleNotesChange}
            value={noteValue}
            placeholder={focused && noteValue.length === 0 ? "" : ""}
            variant="outlined"
            color="secondary"
            disabled={
              hasNewAccommodation ||
              mainRowInfoObject.displayAccommodationTypes.length === 0
            }
            aria-label={
              noteValue.length > 0 ? "Course notes" : "Type something"
            }
            InputProps={{
              maxLength: 4000,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    position="end"
                    className={classes.iconButton}
                    onClick={handleSaveNotes}
                    tabIndex={0}
                    role="button"
                    aria-label="Save notes"
                    disabled={!noteHasChanged}
                  >
                    <SaveIcon
                      aria-hidden="true"
                      className={
                        noteHasChanged
                          ? classes.saveIcon
                          : classes.saveIconDisabled
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            className={
              hasNewAccommodation ||
              mainRowInfoObject.displayAccommodationTypes.length === 0
                ? classes.textFieldGray
                : classes.textField
            }
            label={
              noteValue.length > 0 && !focused
                ? ""
                : focused
                ? noteValue.length === 0
                  ? "Optional field"
                  : "Optional field"
                : "Type something..."
            }
            InputLabelProps={{
              shrink: noteValue.length > 0 || focused,
              "aria-hidden": "true",
            }}
            onFocus={handleFocus}
          />
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Select
            value={gradeValue ? "Yes" : "No"}
            onChange={handleGradeChange}
            displayEmpty
            variant="outlined"
            color="secondary"
            className={
              hasNewAccommodation ||
              mainRowInfoObject.displayAccommodationTypes.length === 0
                ? classes.selectGray
                : classes.select
            }
            disabled={
              hasNewAccommodation ||
              mainRowInfoObject.displayAccommodationTypes.length === 0
            }
          >
            <MenuItem
              value="Yes"
              aria-label="Yes"
              className={classes.menuItemFocused}
            >
              Yes
            </MenuItem>
            <MenuItem
              value="No"
              aria-label="No, negative"
              className={classes.menuItemFocused}
            >
              No
            </MenuItem>
          </Select>
        </TableCell>
        <TableCell align="center">
          {hasNewAccommodation ||
          mainRowInfoObject.displayAccommodationTypes.length === 0 ? (
            <>
              <div>
                <WarningIcon
                  className={classes.warningIcon}
                  aria-label="warning icon"
                  aria-hidden="false"
                />
              </div>
              <Button
                href="#"
                className={classes.linkText}
                role="button"
                onClick={handleOpenModal}
                disableRipple
              >
                <div>
                  <Typography>
                    {hasAcknowledgedAccommodation
                      ? "Updated student"
                      : "New student"}
                  </Typography>
                  <Typography>accommodation</Typography>
                </div>
              </Button>
            </>
          ) : (
            <>
              <Button
                href="#"
                role="button"
                className={classes.linkText}
                aria-label="Review Notification link"
                onClick={handleOpenModal}
                disableRipple
              >
                <Typography>Review Notification</Typography>
              </Button>
            </>
          )}
        </TableCell>
      </TableRow>
      {openSuccess && renderSnackbar()}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    personsWithName: state.personsWithName,
    facultyDemographics: state.demographics,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPersonInfoWithName: (irn, personType) =>
      dispatch(getPersonInfoWithName(irn, personType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseTableRow);
