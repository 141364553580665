import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import styles from "../styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import { postStudentAccommodation } from "../../../services/faculty/v1/faculty";
import { connect } from "react-redux";

function ActionNeeded(props) {
  const useStyles = styles;
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const modalContentRef = useRef(null);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("");

  // =========================================================================================================================
  const handlePostStudentAccommodation = async (accommodation) => {
    await postStudentAccommodation(accommodation)
      .then((res) => {
        setSnackbarType("success");
        setSnackbarMessage("Created student accommodation");
        setOpenSuccess(true);
        renderSnackbar();
        props.setUpdate({
          studentIrn: `${accommodation.studentIrn}`,
          courseOfferingId: `${accommodation.courseOfferingId}`,
        });
        props.setOpenModal(false);
      })
      .catch((error) => {
        console.log(error);
        setSnackbarType("error");
        setSnackbarMessage("Failed to create student accommodation.");
        setOpenSuccess(true);
        renderSnackbar();
      });
  };

  const handleGotItClick = () => {
      // Helper function to create base accommodation object
      const createBaseObject = () => ({
        studentAccommodationInfoId:
          props.actionNeededObject.studentAccommodationInfoId,
        facultyIrn: props.actionNeededObject.facultyIrn,
        facultyPersonId: props.actionNeededObject.facultyPersonId,
        studentIrn: props.actionNeededObject.studentIrn,
        studentPersonId: props.actionNeededObject.studentPersonId,
        courseOfferingId: props.actionNeededObject.courseOfferingId,
        notes: props.actionNeededObject.notes,
        isFinalGradePosted: props.actionNeededObject.finalGradePosted,
        dateModified: new Date().toISOString(),
        modifiedBy: props.facultyDemographics?.username,
      });

      if (props.actionNeededObject.receivingFromFwsDatabase) {
        const isWithinDateRange = (accommodation) => {
          if (
            !(
              accommodation.accommodationStartDate === null &&
              accommodation.accommodationEndDate === null
            )
          ) {
            const startDate = new Date(accommodation.accommodationStartDate);
            const endDate = new Date(accommodation.accommodationEndDate);
            const courseStartDate = props.actionNeededObject.courseStartDate
              ? new Date(props.actionNeededObject.courseStartDate)
              : null;
            const courseEndDate = props.actionNeededObject.courseEndDate
              ? new Date(props.actionNeededObject.courseEndDate)
              : null;
            return (
              (endDate &&
                endDate >= courseStartDate &&
                endDate <= courseEndDate) ||
              (startDate &&
                startDate >= courseStartDate &&
                startDate <= courseEndDate) ||
              (startDate &&
                endDate &&
                startDate <= courseStartDate &&
                endDate >= courseEndDate)
            );
          }
          return true;
        };

        const isCourseMatch = (accommodation) => {
          return accommodation.courseId === null
            ? true
            : accommodation.courseId === props.actionNeededObject.courseId
            ? true
            : false;
        };

        const isApproved = (accommodation) => {
          return (
            accommodation.status?.toLowerCase() === "approved" ||
            accommodation.status === null
          );
        };

        const unacknowledgedAccommodations = props.actionNeededObject.dbResponse
          ?.filter(
            (ack) =>
              isWithinDateRange(ack) &&
              isCourseMatch(ack) &&
              isApproved(ack) &&
              !ack.dateAcknowledged
          )
          .map((ack) => ({
            ...ack,
            modifiedBy: props.facultyDemographics?.username,
            dateModified: new Date().toISOString(),
            dateAcknowledged: new Date().toISOString(),
          }));

        const tempObject = {
          ...createBaseObject(),
          dateCreated:
            props.actionNeededObject.courseOfferingId !== 0
              ? props.actionNeededObject.dateCreated
              : new Date().toISOString(),
          createdBy:
            props.actionNeededObject.courseOfferingId !== 0
              ? props.actionNeededObject.createdBy
            
              : props.facultyDemographics?.username,

          facultyAcknowledgements: unacknowledgedAccommodations,
        };

        handlePostStudentAccommodation(tempObject);
      } else {
        const handleCriteriaMet = (accommodation) => {
          const courseStartDate = props.actionNeededObject.courseStartDate
            ? new Date(props.actionNeededObject.courseStartDate)
            : null;
          const courseEndDate = props.actionNeededObject.courseEndDate
            ? new Date(props.actionNeededObject.courseEndDate)
            : null;
          const startDate = accommodation.startDate
            ? new Date(accommodation.startDate)
            : null;
          const endDate = accommodation.endDate
            ? new Date(accommodation.endDate)
            : null;
          const isApproved = accommodation.status?.toLowerCase() === "approved";
          // if courseId is null - it is not specific to a course. otherwise, courseId has to match
          const accommodationCourseMatch =
            accommodation.courseId === null
              ? true
              : accommodation.courseId === props.actionNeededObject.courseId
              ? true
              : false;

          if (isApproved && accommodationCourseMatch) {
            const conditions = [
              // End date exists and is within range of course start date and course end date
              endDate && endDate >= courseStartDate && endDate <= courseEndDate,
              // Start date exists and is within range of course start date and course end date
              startDate &&
                startDate >= courseStartDate &&
                startDate <= courseEndDate,
              // Both dates exist and start date is before course start date and end date is after course end date
              startDate &&
                endDate &&
                startDate <= courseStartDate &&
                endDate >= courseEndDate,
              // Both dates are null
              !startDate && !endDate,
              // Only startDate exists and current date is after startDate
              startDate && !endDate && courseStartDate >= startDate,
              // Only endDate exists and current date is before endDate
              !startDate && endDate && courseEndDate <= endDate,
            ];

            if (conditions.some((condition) => condition)) {
              return true;
            }
          }

          return false;
        };

        const createAcknowledgement = (
          accommodationObject,
          accommodationType,
          isTemp = false,
          isSpecialArr = false
        ) => ({
          disabilityType: "ADA",
          accommodationType: accommodationType,
          courseId: accommodationObject.courseId
            ? accommodationObject.courseId
            : null,
          specialArrangements: isSpecialArr
            ? null
            : accommodationObject.specialArrangements,
          accommodationStartDate: isTemp ? accommodationObject.startDate : null,
          accommodationEndDate: isTemp ? accommodationObject.endDate : null,
          status: !isTemp ? null : accommodationObject.status,
          active: !isTemp
            ? true
            : accommodationObject.status?.toLowerCase() === "approved"
            ? true
            : false,
          dateCreated: new Date().toISOString(),
          dateModified: new Date().toISOString(),
          createdBy: props.facultyDemographics?.username,
          modifiedBy: props.facultyDemographics?.username,
          dateAcknowledged: new Date().toISOString(),
        });

        const dbResponse = props.actionNeededObject?.dbResponse;
        const permAccommodationTypes = props.actionNeededObject?.dbResponse?.accommodationType 
          ? props.actionNeededObject?.dbResponse?.accommodationType?.split(";")
          : [];
        const permSpecialArrangements = [props.actionNeededObject?.dbResponse];
        const tempFacultyAcknowledgements = dbResponse.isActive
          ? [
              ...(permAccommodationTypes
                ?.filter((acc) => acc)
                ?.map((acc) =>
                  createAcknowledgement(
                    dbResponse, 
                    acc
                  )
                )
              ),

              ...(permSpecialArrangements
                ?.filter((acc) => acc)
                ?.map((acc) => 
                  createAcknowledgement(
                    acc,
                    acc.specialArrangements,
                    false,
                    true
                  )
                )
              ),

              ...dbResponse.tempAccoms
                ?.filter((acc) => handleCriteriaMet(acc) && acc.accommodationType)
                .map((acc) => {
                  return acc.accommodationType
                    ?.split(";")
                    .map((accType) =>
                      createAcknowledgement(acc, accType, true)
                    );
                })
                ?.flat(),

              ...dbResponse.tempAccoms
                ?.filter((acc) => handleCriteriaMet(acc) && acc.specialArrangements && acc.accommodationType)
                .map((acc) =>
                  createAcknowledgement(
                    acc,
                    acc.specialArrangements,
                    true,
                    true
                  )
                ),
            ]
          : [
              ...dbResponse.tempAccoms
                ?.filter((acc) => handleCriteriaMet(acc) && acc.accommodationType)
                .map((acc) => {
                  return acc.accommodationType
                    ?.split(";")
                    .map((accType) =>
                      createAcknowledgement(acc, accType, true)
                    );
                })
                ?.flat(),

              ...dbResponse.tempAccoms
                ?.filter((acc) => handleCriteriaMet(acc) && acc.specialArrangements && acc.accommodationType)
                .map((acc) =>
                  createAcknowledgement(
                    acc,
                    acc.specialArrangements,
                    true,
                    true
                  )
                ),
            ];

        const tempObject = {
          ...createBaseObject(),
          dateCreated: new Date().toISOString(),
          createdBy: props.facultyDemographics?.username,
          facultyAcknowledgements: tempFacultyAcknowledgements,
        };

        handlePostStudentAccommodation(tempObject);
      }
    // }
  };
  // =========================================================================================================================

  // =========================================================================================================================
  // Handles Modal navigation and accessibility.
  useEffect(() => {
    const handleScroll = () => {
      if (modalContentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          modalContentRef.current;

        const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - 5;
        setScrolledToBottom(isScrolledToBottom);
      }
    };

    const modalContentElement = modalContentRef.current;
    if (modalContentElement) {
      modalContentElement.addEventListener("scroll", handleScroll);
      handleScroll();
    }

    return () => {
      if (modalContentElement) {
        modalContentElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (modalContentRef.current) {
      switch (event.key) {
        case "ArrowUp":
          modalContentRef.current.scrollBy({
            top: -220,
            behavior: "smooth",
          });
          break;
        case "ArrowDown":
          modalContentRef.current.scrollBy({
            top: 220,
            behavior: "smooth",
          });
          break;
        default:
          break;
      }
    }
  };
  // =========================================================================================================================

  // =========================================================================================================================
  // Handles rendering of snackbar to screen after successful or failed acknowledgements POST endpoint call.
  const renderSnackbar = () => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={snackbarType === "success" ? 10000 : 13000}
        onClose={handleCloseSnackBar}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={snackbarMessage}
        />
      </Snackbar>
    </>
  );

  const handleCloseSnackBar = (event, reason) => {
    setOpenSuccess(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const handleRetryApiCall = () => {
    switch (snackbarMessage) {
      case "Failed to create student accommodation.":
        handleGotItClick();
        break;
      default:
        break;
    }
  };
  // =========================================================================================================================

  return (
    <>
      <Grid
        container
        role="dialog"
        aria-labelledby="action-needed-modal-title"
        className={classes.actionContainer}
        component={Paper}
        elevation={2}
        xs={12}
      >
        <Box
          className={
            matches
              ? classes.closeButtonContainer
              : classes.mobileCloseButtonContainer
          }
          // logic for disabling will need to be replaced when we have db set up tracking acknowledgement
          onClick={
            props.alreadyAcknowledged
              ? () => props.setOpenModal(false)
              : scrolledToBottom
              ? () => props.setOpenModal(false)
              : null
          }
          tabIndex={props.alreadyAcknowledged ? 0 : scrolledToBottom ? 0 : -1}
          onKeyDown={(event) => {
            if (event.key === "Escape") {
              event.preventDefault();
              props.setOpenModal(false);
              return;
            }
            if (
              (props.alreadyAcknowledged || scrolledToBottom) &&
              (event.key === "Enter" || event.key === " ")
            ) {
              event.preventDefault();
              props.setOpenModal(false);
            }
          }}
          role="button"
          aria-label="Close"
          aria-disabled={props.alreadyAcknowledged || !scrolledToBottom}
          style={{
            cursor: props.alreadyAcknowledged
              ? "pointer"
              : scrolledToBottom
              ? "pointer"
              : "not-allowed",
            opacity: props.alreadyAcknowledged ? 1 : scrolledToBottom ? 1 : 0.5,
          }}
        >
          <Typography className={classes.closeButtonText}>Close</Typography>
        </Box>
        <Box
          className={classes.mainPopUpContent}
          ref={modalContentRef}
          style={{ overflowY: "auto", outline: "none" }}
        >
          <Box>
            <Typography
              className={classes.titleText}
              component="h2"
              id="action-needed-modal-title"
            >
              Action needed: New student accommodation
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.greetingText}>
              {`Hello ${props.facultyDemographics.firstName} ${props.facultyDemographics.lastName},`}
            </Typography>
          </Box>
          <Box className={classes.infoContainer}>
            <Typography className={classes.boldText}>
              Regarding: &nbsp;
              <Typography>
                {props.actionNeededObject?.studentName.includes("null")
                  ? ""
                  : props.actionNeededObject?.studentName}
              </Typography>
            </Typography>
            <Typography className={classes.boldText}>
              IRN: &nbsp;
              <Typography>{props.actionNeededObject?.studentIrn}</Typography>
            </Typography>
            <Typography className={classes.boldText}>
              Course: &nbsp;
              <Typography>{props.actionNeededObject?.courseId}</Typography>
            </Typography>
            <Typography className={classes.boldText}>
              Course Start Date: &nbsp;
              <Typography>
                {props.actionNeededObject?.courseStartDate}
              </Typography>
            </Typography>
            <Typography className={classes.boldText}>
              Course End Date: &nbsp;
              <Typography>{props.actionNeededObject?.courseEndDate}</Typography>
            </Typography>
          </Box>
          <Box style={{ marginBottom: "24px" }}>
            <Typography>
              This student is approved for the following accommodation(s) under
              University policy.
            </Typography>
          </Box>

          <Box style={{ marginBottom: "24px" }}>
            <Typography component="h3" className={classes.subHeadingText}>
              Approved Accommodations:
            </Typography>
          </Box>

          <Box style={{ marginBottom: "24px" }}>
            <Typography className={classes.accomText}>
              {props.actionNeededObject.displayAccommodationTypes.length > 0 &&
                props.actionNeededObject.displayAccommodationTypes.map(
                  (accommodation, index) => (
                    <React.Fragment key={index}>
                      <Typography display="inline">
                        {`${accommodation}`}
                        {index <
                          props.actionNeededObject.displayAccommodationTypes
                            .length -
                            1 && <span>{`; `}</span>}
                      </Typography>
                    </React.Fragment>
                  )
                )}
            </Typography>
          </Box>

          <Box className={classes.textBlock}>
            <Typography display="inline">
              {`Faculty may access information about accommodations through the `}
              <Button
                href={
                  process.env.REACT_APP_STUDENT_ACCOMMODATION_DICTIONARY_URL
                }
                target="_blank"
                className={classes.popUpLinkText}
                disableRipple
              >
                {`Accommodation Dictionary`}
              </Button>
              {` available on the Faculty Resource Center. Students will access the `}
              <Button
                href="https://my.phoenix.edu/kb/article/Student-Accommodations-Dictionary-SAO"
                target="_blank"
                className={classes.popUpLinkText}
                disableRipple
              >
                {`Student Accommodation Dictionary`}
              </Button>
              {` with the same information in the My
            Phoenix Help Topics.`}
            </Typography>
          </Box>

          <Box className={classes.textBlock}>
            <Typography display="inline">
              {`The `}
              <Button
                href={`${process.env.REACT_APP_STUDENT_ACCOMMODATION_DICTIONARY_URL}#Faculty-Responsibilities-ADA`}
                target="_blank"
                className={classes.popUpLinkText}
                disableRipple
              >
                {`Faculty Responsibilities – ADA`}
              </Button>
              {` outlines your responsibilities. Please review in the Resource section of the Accommodation Dictionary.`}
            </Typography>
          </Box>

          <Box className={classes.textBlock}>
            <Typography display="inline">
              {`Please also review `}
              <Button
                href={`${process.env.REACT_APP_STUDENT_ACCOMMODATION_DICTIONARY_URL}#Setting-Up-Accommodations-In-Blackboard`}
                target="_blank"
                className={classes.popUpLinkText}
                disableRipple
              >
                {`Setting Up Accommodations in Blackboard`}
              </Button>
              {`, which will help you designate the students who receive accommodations. Additional resources for managing accommodated students in Blackboard are also provided.`}
            </Typography>
          </Box>

          <Box className={classes.textBlock}>
            <Typography display="inline">
              {`All information pertaining to the student’s accommodations must remain confidential. The reason the student qualifies for accommodations is not disclosed to faculty and is not information faculty needs to know to implement accommodations in a course.`}
            </Typography>
          </Box>

          <Box className={classes.textBlock}>
            <Typography display="inline">
              {`If you have any questions regarding this information, please do not hesitate to contact us for more information. Have a great day and thank you for your continued efforts to assist our students.`}
            </Typography>
          </Box>

          <Box className={classes.thankyouContainer}>
            <Typography> Thank you, </Typography>
            <Typography className={classes.justBold}>
              Your Student Accommodations Team
            </Typography>
          </Box>
        </Box>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Button
            className={classes.gotItButton}
            style={{
              alignSelf: "center",
              borderRadius: "30px",
              padding: `${matches ? "8px 32px" : "2px 16px"}`,
              margin: "30px 16px 10px 0px",
              width: matches ? "253px" : "200px",
              height: "51px",
              backgroundColor: props.alreadyAcknowledged
                ? "#B0B0B0"
                : scrolledToBottom
                ? "#DC3727"
                : "#B0B0B0",
              color: props.alreadyAcknowledged
                ? "#6D6D6D"
                : scrolledToBottom
                ? "white"
                : "#6D6D6D",
              textTransform: "none",
            }}
            aria-label="Got it, acknowledgement"
            variant="contained"
            onClick={handleGotItClick}
            disabled={props.alreadyAcknowledged ? true : !scrolledToBottom}
          >
            Got it!
          </Button>
        </div>
        {openSuccess && renderSnackbar()}
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    facultyDemographics: state.demographics,
  };
};

export default connect(mapStateToProps)(ActionNeeded);
